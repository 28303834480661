import { CallType } from '../models/Call';
import { Facility, IFacility } from '../models/Facility';
import { IRate, IRatePlan } from '../models/RatePlan';
import { Service } from '../reducers/checkout';

/**
 * Get the appropriate billing category based on service type
 */
export function getBillingCategoryForService(service: Service): string {
  switch (service) {
    case Service.Wallet:
      return 'wallet-call';
    case Service.PhoneCard:
      return 'card-account-call';
    case Service.Collect:
      return 'directbill-call';
    default:
      return null;
  }
}

/**
 * Get rate by call type, category and billing category
 */
export function getRate(
  ratePlan: IRatePlan,
  callType: CallType,
  category: string,
  billingCategory: string
): IRate|null {
  return ratePlan.rates.find(rate =>
    rate.callType === callType &&
    rate.category === category &&
    rate.billingCategory === billingCategory
  );
}

/**
 * Get the default display rate (in-state) for a given service and category
 */
export function getDefaultRate(
  facility: Facility | IFacility,
  service: Service,
  category: string = 'audio-call'
): number | null {
  const billingCategory = getBillingCategoryForService(service);
  const inStateRate = getRate(
    facility.ratePlan,
    CallType.InState,
    category,
    billingCategory
  );

  return inStateRate?.perMinuteCharge;
}

/**
 * Get the international rate for a given service and category
 */
export function getInternationalRate(
  facility: Facility | IFacility,
  service: Service,
  category: string = 'audio-call'
): number | null {
  const billingCategory = getBillingCategoryForService(service);
  const internationalRate = getRate(
    facility.ratePlan,
    CallType.International,
    category,
    billingCategory
  );

  return internationalRate?.perMinuteCharge;
}

/**
 * Get the out-of-state rate for a given service and category
 */
export function getOutOfStateRate(
  facility: Facility | IFacility,
  service: Service,
  category: string = 'audio-call'
): number | null {
  const billingCategory = getBillingCategoryForService(service);
  const outOfStateRate = getRate(
    facility.ratePlan,
    CallType.OutOfState,
    category,
    billingCategory
  );

  return outOfStateRate?.perMinuteCharge;
}

/**
 * Get the local call rate for a given service and category
 */
export function getLocalRate(
  facility: Facility | IFacility,
  service: Service,
  category: string = 'audio-call'
): number | null {
  const billingCategory = getBillingCategoryForService(service);
  const localRate = getRate(
    facility.ratePlan,
    CallType.Local,
    category,
    billingCategory
  );

  return localRate?.perMinuteCharge ?? 0.25;
}
