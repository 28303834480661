import React from 'react';
import { ApiError } from '../../../services/api';
import ErrorMessage from '../../ErrorMessage';

interface IProcessingErrorProps {
  error: ApiError
}

const ProcessingError: React.FC<IProcessingErrorProps> = props => {
  const { error } = props;

  // Handle specific error conditions
  if (error.data && error.data.error && error.data.error.field && error.data.error.error) {
    switch (error.data.error.field) {
      case 'amount':
        if (error.data.error.error == 'exceedsmaximum') {
          return <ErrorMessage message={`The payment cannot be more than $${error.data.error.maximum}`} />
        }
        break;

      case 'ani':
        if (error.data.error.error == 'paymenthold') {
          return <ErrorMessage message='Your account has a payment hold on it. Please contact Reliance Telephone at 1-800-896-3201 between 8am-6pm CST.' />
        }
        break;

      case 'cc':
        if (error.data.error.error == 'cchold') {
          return <ErrorMessage message='This credit card has a hold on it. Please contact Reliance Telephone at 1-800-896-3201 between 8am-6pm CST.' />
        }
    }
  }

  return (
    <ErrorMessage
      error={error}
      httpGenericMessage={'An error occurred while processing your payment'}
      httpCodeMessages={{
        402: 'The credit card charge was declined',
        423: 'This credit card has a hold on it. Please contact Reliance Telephone at 1-800-896-3201 between 8am-6pm CST.',
        409: 'Your account has a payment hold on it. Please contact Reliance Telephone at 1-800-896-3201 between 8am-6pm CST.'
      }} />
  )
}

export default ProcessingError;
