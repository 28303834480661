import React from 'react';

const RefundInformation = (
  <>
    <p className="font-weight-bold mb-0"><u>Refunds</u></p>
    <p>Refunds for Direct Bill accounts can be obtained by calling 1-800-896-3201. Please be ready to provide the name and address of the person who purchased the phone card.</p>
  </>
)

export default RefundInformation;
