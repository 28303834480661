import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import contactSelect from '../../../images/contact-import/android/contact-select.png';
import contactsAppImageAndroid from '../../../images/contact-import/android/contacts.png';
import contactExportEmailImageAndroid from '../../../images/contact-import/android/export-to-email.png';
import selectedContacts from '../../../images/contact-import/android/select-contacts.png';
import share from '../../../images/contact-import/android/share.png';
import contactsAppImageIos from '../../../images/contact-import/ios/contacts-app.png';
import contactExportEmailImageIos from '../../../images/contact-import/ios/export-to-email.png';
import contactExportImage from '../../../images/contact-import/ios/export.png';
import contactsGoToListsImage from '../../../images/contact-import/ios/go-to-lists.png';
import contactListsImage from '../../../images/contact-import/ios/lists.png';
import contactSelectExportImage from '../../../images/contact-import/ios/select-export.png';
import ContactImportBenefits from './ContactImportBenefits';

const ContactImport: React.FC = () => {
  const iosSteps = [
    {
      title: "Find Your Contacts",
      instruction: "Look for the Contacts app on your iPhone and tap to open it",
      image: contactsAppImageIos,
      label: "STEP ONE"
    },
    {
      title: "Go to Lists",
      instruction: "At the top of your screen, tap on 'Lists' to see all your contacts",
      image: contactsGoToListsImage,
      label: "STEP TWO"
    },
    {
      title: "Select All Contacts",
      instruction: "Press and hold on 'All iCloud' until a menu pops up",
      image: contactListsImage,
      label: "STEP THREE"
    },
    {
      title: "Export Your List",
      instruction: "Tap 'Export' from the menu that appears",
      image: contactSelectExportImage,
      label: "STEP FOUR"
    },
    {
      title: "Confirm Export",
      instruction: "Tap 'Done' on the box that appears to create your contact file. Make sure the contacts name and phone numbers are selected",
      image: contactExportImage,
      label: "STEP FIVE"
    },
    {
      title: "Choose Email",
      instruction: "Pick your email app from the sharing options that appear",
      image: contactExportEmailImageIos,
      label: "STEP SIX"
    },
    {
      title: "Send Your Contacts",
      instruction: "Type contacts@inmates.reliancetelephone.com in the 'To:' field and send your email",
      label: "FINAL STEP",
      isSubmissionStep: true
    }
  ];

  const androidSteps = [
    {
      title: "Open Contacts",
      instruction: "Launch the Contacts app on your Android device",
      image: contactsAppImageAndroid,
      label: "STEP ONE"
    },
    {
      title: "Select Contact",
      instruction: "Long press on a contact until the select option displays",
      image: contactSelect,
      label: "STEP TWO"
    },
    {
      title: "Select Exported Contacts",
      instruction: "Select all contacts you wish to export",
      image: selectedContacts,
      label: "STEP THREE"
    },
    {
      title: "Tap Share",
      instruction: "Tap share to share .VCF file",
      image: share,
      label: "STEP FOUR"
    },
    {
      title: "Choose Email",
      instruction: "Pick your email app from the sharing options that appear",
      image: contactExportEmailImageAndroid,
      label: "STEP FIVE"
    },
    {
      title: "Send Your Contacts",
      instruction: "Type contacts@inmates.reliancetelephone.com in the 'To:' field and send your email",
      label: "FINAL STEP",
      isSubmissionStep: true
    }
  ];

  return (
    <>
      <div className="hero bg-primary text-light py-5">
        <Container className="pt-3">
          <div className="row">
            <div className="col-lg-8 pt-2">
              <h1>Import Your Contacts</h1>
            </div>
          </div>
        </Container>
      </div>

      <ContactImportBenefits />

      <Container className="py-5">
        <Tab.Container defaultActiveKey="ios">
          <Nav variant="pills" className="mb-5 justify-content-center">
            <Nav.Item>
              <Nav.Link eventKey="ios" className="px-5 py-3 d-flex align-items-center">
                <FontAwesomeIcon icon={faApple} className="mr-2" style={{fontSize: "1.5rem"}} />
                <span style={{fontSize: "1.2rem"}}>iPhone (iOS)</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="android" className="px-5 py-3 d-flex align-items-center">
                <FontAwesomeIcon icon={faAndroid} className="mr-2" style={{fontSize: "1.5rem"}} />
                <span style={{fontSize: "1.2rem"}}>Android</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="ios">
              {iosSteps.map((step, index) => (
                <div key={`ios-step-${index}`} className="mb-5">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="text-primary font-weight-bold text-uppercase font-size-6 mb-2">{step.label}</div>
                      <div className="d-flex align-items-start mb-3">
                        <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mr-3 flex-shrink-0"
                            style={{width: "40px", height: "40px", minWidth: "40px"}}>
                          {index + 1}
                        </div>
                        <div>
                          <h3 className="font-size-4 text-primary font-weight-semibold mb-2">{step.title}</h3>
                          <p className="font-size-5 text-muted">{step.instruction}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {!step.isSubmissionStep ? (
                        <div className="bg-light border border-2 rounded-3 d-flex align-items-center justify-content-center">
                          <img
                            src={step.image}
                            alt={`${step.title} screenshot`}
                            className="img-fluid rounded-3"
                            style={{maxHeight: "300px", maxWidth: "100%"}}
                          />
                        </div>
                      ) : (
                        <div className="rounded-3 p-4 d-flex flex-column align-items-center justify-content-center bg-gradient"
                             style={{height: "300px", background: "linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)"}}>
                          <div className="bg-white rounded-2 px-4 py-3 font-size-5 font-weight-semibold shadow-sm">
                            contacts@inmates.reliancetelephone.com
                          </div>
                          <div className="text-muted font-size-6 mt-3">
                            Accepted formats: vCard (.vcf)
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Tab.Pane>

            <Tab.Pane eventKey="android">
              {androidSteps.map((step, index) => (
                <div key={`android-step-${index}`} className="mb-5">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="text-primary font-weight-bold text-uppercase font-size-6 mb-2">{step.label}</div>
                      <div className="d-flex align-items-start mb-3">
                      <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mr-3 flex-shrink-0"
                          style={{width: "40px", height: "40px", minWidth: "40px"}}>
                        {index + 1}
                      </div>
                      <div>
                        <h3 className="font-size-4 text-primary font-weight-semibold mb-2">{step.title}</h3>
                        <p className="font-size-5 text-muted">{step.instruction}</p>
                      </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                      {!step.isSubmissionStep ? (
                        <div className="bg-light border border-2 rounded-3 d-flex align-items-center justify-content-center">
                          <img
                            src={step.image}
                            alt={`${step.title} screenshot`}
                            className="img-fluid rounded-3"
                            style={{maxHeight: "300px", maxWidth: "100%"}}
                          />
                        </div>
                      ) : (
                        <div className="rounded-3 p-4 d-flex flex-column align-items-center justify-content-center bg-gradient"
                             style={{height: "300px", background: "linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)"}}>
                          <div className="bg-white rounded-2 px-4 py-3 font-size-5 font-weight-semibold shadow-sm">
                            contacts@inmates.reliancetelephone.com
                          </div>
                          <div className="text-muted font-size-6 mt-3">
                            Accepted formats: vCard (.vcf)
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
};

export default ContactImport;
