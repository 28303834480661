import { faAddressBook, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';


const PreBooking: React.FC = () => {
  return (
    <>
      <div className="hero bg-primary text-light py-5">
        <Container className="pt-3">
          <div className="row">
            <div className="col-lg-8 pt-2">
              <h1>Preparing for Your Stay</h1>
              <h4 className="pb-3">Stay Connected with Your Inmate Device</h4>
            </div>
          </div>
        </Container>
      </div>

      <Container className="py-5">
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="bg-light p-4 rounded">
              <h4 className="text-primary mb-4">Your Inmate Device Features</h4>
              <ul className="list-unstyled">
                <li className="mb-3 d-flex align-items-center">
                  <span className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3" style={{width: "24px", height: "24px"}}>✓</span>
                  <span className="font-size-5">Your own phone number</span>
                </li>
                <li className="mb-3 d-flex align-items-center">
                  <span className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3" style={{width: "24px", height: "24px"}}>✓</span>
                  <span className="font-size-5">Send & receive text messages</span>
                </li>
                <li className="mb-3 d-flex align-items-center">
                  <span className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3" style={{width: "24px", height: "24px"}}>✓</span>
                  <span className="font-size-5">Make & receive phone calls</span>
                </li>
                <li className="mb-3 d-flex align-items-center">
                  <span className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3" style={{width: "24px", height: "24px"}}>✓</span>
                  <span className="font-size-5">Video calling capabilities</span>
                </li>
                <li className="mb-3 d-flex align-items-center">
                  <span className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3" style={{width: "24px", height: "24px"}}>✓</span>
                  <span className="font-size-5">Access to self-help resources</span>
                </li>
                <li className="mb-3 d-flex align-items-center">
                  <span className="bg-primary text-white rounded-circle d-flex align-items-center justify-content-center mr-3" style={{width: "24px", height: "24px"}}>✓</span>
                  <span className="font-size-5">Entertainment: Music, movies & games</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card shadow-sm border-0">
              <div className="card-body p-4">
                <h4 className="text-primary mb-4">Pre-Booking Checklist</h4>

                <Link to="/pre-booking/contacts" className="text-decoration-none">
                  <div
                    className="bg-primary text-white rounded p-3 mb-4 d-flex align-items-center position-relative shadow-sm"
                    style={{
                      // Slow down the transform animation a little bit
                      transition: "all .2s ease-in-out",
                      cursor: "pointer"
                    }}
                    onMouseOver={(e) => {
                      // Move the element slightly on hover
                      e.currentTarget.style.transform = "translateY(-2px)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "";
                    }}>
                    <FontAwesomeIcon icon={faAddressBook} className="text-white" style={{fontSize: "1.75rem"}} />
                    <div className="ml-3" style={{maxWidth: "calc(100% - 5rem)"}}>
                      <h5 className="text-white mb-0" style={{fontSize: "1.1rem"}}>Import Your Contacts Now</h5>
                      <p className="mb-0 small" style={{color: "rgba(255, 255, 255, 0.85)"}}>Easily transfer your contact list to stay connected</p>
                    </div>
                    <div className="position-absolute d-flex align-items-center justify-content-center" style={{right: "1.25rem", fontSize: "2.5rem", fontWeight: 'bolder', height: "100%", top: "0"}}>
                      <span className="text-white">→</span>
                    </div>
                  </div>
                </Link>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item py-3 d-flex align-items-center">
                    <FontAwesomeIcon icon={faSquare} className="text-primary mr-3" />
                    Notify family members of the jail you'll be at
                  </li>
                  {/* <li className="list-group-item py-3">Set up account funding options</li> */}
                  <li className="list-group-item py-3 d-flex align-items-center">
                    <FontAwesomeIcon icon={faSquare} className="text-primary mr-3" />
                    Learn facility rules and regulations
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PreBooking;
