import React from 'react';
import Sentry from '../services/sentry';
import Header from './Header';
import PageTitle from './PageTitle';

interface IErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  hasError: boolean

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, info);
    this.setState({
      hasError: true
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Header errorMode={true} />

          <PageTitle title="Something Broke!" />

          <main className="container">
            <div className="row py-5 justify-content-center">
              <div className="col-md-6 py-5">
                <h3>Something broke!</h3>

                <p>Yikes, something didn't work out as well as we wanted it to and the page broke. We sent our team an automatic notification of this issue and we will be investigating it as soon as possible!</p>

                <p>In the meantime, feel free to <a href="" onClick={() => window.history.back()}>go back and try again</a>.</p>

                <p>If the issue persists, please contact Reliance Telephone at 1-800-896-3201 between 8am-6pm CST</p>
              </div>
            </div>
          </main>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;