import React from 'react';

const CollectCallInformation = (
  <>
    <p>Collect call accounts are on a prepayment basis and you must maintain a positive credit balance to keep your line open. Any calls received that exceed account balance will be billed to your account and due immediately.</p>
    <p>Payments to your collect call account can be made online or over the phone by calling 1-800-896-3201. Live representatives are available Monday-Friday 8am to 6pm CST. </p>
    <p>Reliance Telephone collect call costs are as follows:</p>
    <p className="font-weight-bold mb-0"><u>Collect Local Calls</u></p>
    <p>$0.21 - $0.25 per minute + applicable taxes (Rate may vary depending on facility)</p>
    <p className="font-weight-bold mb-0"><u>Collect In-State Calls</u></p>
    <p>$0.21 - $0.25 per minute + applicable taxes (Rate may vary depending on facility)</p>
    <p className="font-weight-bold mb-0"><u>Collect State to State Calls</u></p>
    <p>$0.21 per minute + applicable taxes + Federal Universal Service Fund</p>
  </>
)

export default CollectCallInformation;
