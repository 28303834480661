import React from 'react';
import { Container } from 'react-bootstrap';

const ContactImportBenefits: React.FC = () => (
  <div className="bg-light py-5">
    <Container>
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4 p-lg-5">
              <h2 className="text-center mb-4">Why Import Your Contacts?</h2>

              <div className="row gutters-4">
                <div className="col-md-4">
                  <div className="text-center h-100 p-4">
                    <div className="mb-3">
                      <i className="fas fa-comments fa-2x text-primary"></i>
                    </div>
                    <h4 className="mb-3">Stay Connected</h4>
                    <p className="mb-0">Text, call, and video chat with your loved ones right from your personal device</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="text-center h-100 p-4">
                    <div className="mb-3">
                      <i className="fas fa-address-book fa-2x text-primary"></i>
                    </div>
                    <h4 className="mb-3">Bring Your Contacts</h4>
                    <p className="mb-0">Have all your important contacts ready to use. No need to memorize phone numbers</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="text-center h-100 p-4">
                    <div className="mb-3">
                      <i className="fas fa-clock fa-2x text-primary"></i>
                    </div>
                    <h4 className="mb-3">Save Time</h4>
                    <p className="mb-0">Import now instead of trying to collect contact information while in facility</p>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4 pt-3 border-top">
                <div className="alert alert-info d-inline-block mx-auto" style={{maxWidth: "600px"}}>
                  <strong>Important:</strong> Complete this before entering the facility to ensure you can connect with everyone important to you.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default ContactImportBenefits;
